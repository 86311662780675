<template>
  <CListGroup flush v-if=voiceEdrs.length>
    <CListGroupItem v-for="(voiceEDR, key) in voiceEdrs" :key="key">
      <CCardTitle class="pt-3">{{ voiceEDR.name }}</CCardTitle>
      <CTable v-if="voiceEDR.isMonetary">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>Time</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Calling Number</CTableHeaderCell>
            <CTableHeaderCell class="text-center"></CTableHeaderCell>
            <CTableHeaderCell class="text-center">Called Number</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Duration</CTableHeaderCell>
            <CTableHeaderCell class="text-center">RG</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Base Amt</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Tax Amt</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Total Amt</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Balance Amt</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="(edr, idx) in voiceEDR.edrs" :key="idx">
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1 }">
              {{ edr.time }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.fromParty }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
              <font-awesome-icon icon="fa-solid fa-r" v-if="edr.isRoaming" class="text-primary" />
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.toParty }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.rgTotalTime }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.ratingGroup }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.base }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.tax }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.total }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.newBalanceValue }}
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
      <CTable v-else>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>Time</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Calling Number</CTableHeaderCell>
            <CTableHeaderCell class="text-center"></CTableHeaderCell>
            <CTableHeaderCell class="text-center">Called Number</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Duration</CTableHeaderCell>
            <CTableHeaderCell class="text-center">RG</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Balance</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="(edr, idx) in voiceEDR.edrs" :key="idx">
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1 }">
              {{ edr.time }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.fromParty }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
              <font-awesome-icon icon="fa-solid fa-r" v-if="edr.isRoaming" class="text-primary" />
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.toParty }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.rgTotalTime }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.ratingGroup }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === voiceEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.newBalanceValue }}
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
    </CListGroupItem>
  </CListGroup>
  <CCardBody v-else>
    <CCardTitle class="text-center">No data found</CCardTitle>
  </CCardBody>
</template>
<script>
export default {
  props: {
    voiceEdrs: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>
