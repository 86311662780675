import { axiosInstance as axios } from '../../utils/axiosInstances'

import { notificationsUrl } from '@/store/common'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async update (context, { accountId, deviceId }) {
      const idToken = await context.rootState.idToken
      const providerId = context.rootState.providerId
      let url = `${notificationsUrl}notifications?providerId=${providerId}&accountId=${accountId}`
      let result = await axios.get(
        url, {
          headers: {
            Authorization: `${idToken}`
          }
        }
      )
      let notifications = result.data.items
      for (let i = 0; i < notifications.length; i++) {
        context.commit('addLog', {
          deviceId: deviceId,
          log: {
            id: notifications[i].timestamp,
            text: notifications[i].message,
            style: 'notification'
          }
        }, { root: true })
      }
      url = `${notificationsUrl}notifications?accountId=${accountId}&deviceId=${deviceId}`
      result = await axios.get(
        url, {
          headers: {
            Authorization: `${idToken}`
          }
        }
      )
      notifications = result.data.items
      for (let i = 0; i < notifications.length; i++) {
        context.commit('addLog', {
          deviceId: deviceId,
          log: {
            id: notifications[i].timestamp,
            text: notifications[i].message,
            style: 'notification'
          }
        }, { root: true })
      }
      url = `${notificationsUrl}notifications?accountId=undefined&deviceId=${deviceId}`
      result = await axios.get(
        url, {
          headers: {
            Authorization: `${idToken}`
          }
        }
      )
      notifications = result.data.items
      for (let i = 0; i < notifications.length; i++) {
        context.commit('addLog', {
          deviceId: deviceId,
          log: {
            id: notifications[i].timestamp,
            text: notifications[i].message,
            style: 'notification'
          }
        }, { root: true })
      }
    }
  }
}
