<template>
  <CListGroup flush v-if="topupEdrs.length">
    <CListGroupItem>
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>
              Time
            </CTableHeaderCell>
            <CTableHeaderCell>
              Called By
            </CTableHeaderCell>
            <CTableHeaderCell>
              Adj Type
            </CTableHeaderCell>
            <CTableHeaderCell class="text-end">
              Amount
            </CTableHeaderCell>
            <CTableHeaderCell class="text-end">
              Balance Before
            </CTableHeaderCell>
            <CTableHeaderCell class="text-end">
              Balance After
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="(topUp, idx) in topupEdrs" :key="idx">
            <CTableDataCell :class="{ 'balances-table-last-row': idx === topupEdrs.length - 1 }">
              {{ topUp.time }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === topupEdrs.length - 1 }" :title="topUp.calledByOriginal">
              {{ topUp.calledBy }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === topupEdrs.length - 1 }">
              {{ topUp.adjustmentType }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === topupEdrs.length - 1, 'text-end': true }">
              {{ topUp.amount }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === topupEdrs.length - 1, 'text-end': true }">
              {{ topUp.balanceBefore }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === topupEdrs.length - 1, 'text-end': true }">
              {{ topUp.balanceAfter }}
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
    </CListGroupItem>
  </CListGroup>
  <CCardBody v-else>
    <CCardTitle class="text-center">No Topups found</CCardTitle>
  </CCardBody>
</template>
<script>
export default {
  props: {
    topupEdrs: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>
