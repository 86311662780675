<template>
  <CListGroup flush v-if="subscriptionEdrs.length">
    <CListGroupItem>
      <CTable>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>
              Time
            </CTableHeaderCell>
            <CTableHeaderCell>Plan Subscription ID</CTableHeaderCell>
            <CTableHeaderCell>Plan Name</CTableHeaderCell>
            <CTableHeaderCell class="text-end">
              Amount
            </CTableHeaderCell>
            <CTableHeaderCell class="text-end">
              Balance Before
            </CTableHeaderCell>
            <CTableHeaderCell class="text-end">
              Balance After
            </CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="(subscriptionEdr, idx) in subscriptionEdrs" :key="idx">
            <CTableDataCell :class="{ 'balances-table-last-row': idx === subscriptionEdrs.length - 1 }">
              {{ subscriptionEdr.time }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === subscriptionEdrs.length - 1, 'text-end': false }">
              <TotBadge v-if="subscriptionEdr.planSubscriptionId" :copyValue="subscriptionEdr.planSubscriptionId">{{subscriptionEdr.planSubscriptionIdText}}</TotBadge>
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === subscriptionEdrs.length - 1, 'text-end': false }">
              {{ planVersionNameMap[subscriptionEdr.planVersionId] }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === subscriptionEdrs.length - 1, 'text-end': true }">
              {{ subscriptionEdr.amount }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === subscriptionEdrs.length - 1, 'text-end': true }">
              {{ subscriptionEdr.balanceBefore }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === subscriptionEdrs.length - 1, 'text-end': true }">
              {{ subscriptionEdr.balanceAfter }}
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
    </CListGroupItem>
  </CListGroup>
  <CCardBody v-else>
    <CCardTitle class="text-center">No Subscriptions found</CCardTitle>
  </CCardBody>
</template>
<script>
import TotBadge from './common/tot-badge'

export default {
  components: {
    TotBadge
  },
  props: {
    subscriptionEdrs: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    planVersionNameMap () {
      return (this.$store.state.device.planVersionNameMap || {})
    }
  }
}
</script>
