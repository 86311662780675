<template>
  <div>
    <b>Your Provider:</b> {{ $store.state.providerId }}<br />
    <b>Current Plan Name:</b> {{ $store.state.currentPlanInformation.planName }}<br />
    <b>Current Plan ID:</b> {{ $store.state.currentPlanInformation.planId }}<br />
    <b>Plan Version Name:</b> {{ $store.state.currentPlanInformation.planVersionName }}<br />
    <b>Plan Version ID:</b> {{ $store.state.currentPlanInformation.planVersionId }}<br />
    <b>Rating Group 100 Unit Type:</b>
    <CFormSelect
      aria-label="Select unity type of rating group 100"
      @change="changeUnitType(100, $event.target.value)"
      :options="defaultOptions(100)"
    >
    </CFormSelect>
    <b>Rating Group 200 Unit Type:</b>
    <CFormSelect
      aria-label="Select unity type of rating group 200"
      @change="changeUnitType(200, $event.target.value)"
      :options="defaultOptions(200)"
    >
    </CFormSelect>
    <b>Rating Group 300 Unit Type:</b>
    <CFormSelect
      aria-label="Select unity type of rating group 300"
      @change="changeUnitType(300, $event.target.value)"
      :options="defaultOptions(300)"
    >
    </CFormSelect>
    <b>Rating Group 400 Unit Type:</b>
    <CFormSelect
      aria-label="Select unity type of rating group 400"
      @change="changeUnitType(400, $event.target.value)"
      :options="defaultOptions(400)"
    >
    </CFormSelect>
    <b>Verbose Logging:</b>
    <div id="verboseLogging">
      <CButton
        @click="this.$store.dispatch('logging/toggleVerbosity')"
        color="info"
        shape="rounded-pill"
      >Verbose Logging: {{this.$store.state.verboseLogging ? 'ON' : 'OFF'}}
    </CButton>
    </div>
    <br />
    <CForm>
      <CFormInput
        v-model="mcc"
        type="text"
        id="mcc"
        label="Mobile Country Code"
        placeholder="310"
        text="the mobile country code you want to send traffic in on"
        aria-describedby="the mobile country code you want to send traffic in on"
      />
      <CFormInput
        v-model="mnc"
        type="text"
        id="mnc"
        label="Mobile Network Code"
        placeholder="170"
        text="the mobile network code you want to send traffic in on"
        aria-describedby="the mobile network code you want to send traffic in on"
      />
    </CForm>
    <div id="verboseLogging">
      <CButton
        @click="updateMncMccValues()"
        color="info"
        shape="rounded-pill"
      >Update MNC/MCC</CButton>
    </div>
    <hr />
    <h4 class="pb-2">On-Net/Off-Net</h4>
    <CFormInput
      v-model="carrierSelectRoutingInformation"
      type="text"
      id="carrierSelectRoutingInformation"
      label="Carrier Select Routing Information"
      placeholder="ARIN"
      aria-describedby="Carrier Select Routing Information"
      @change="updatecarrierSelectRoutingInformation"
    />
    <CFormInput
      v-model="numberPortabilityRoutingInformation"
      type="text"
      id="numberPortabilityRoutingInformation"
      label="Number Portability Routing Information"
      placeholder="ARIN"
      aria-describedby="Number Portability Routing Information"
      @change="updateNumberPortabilityRoutingInformation"
    />
    <CFormSwitch v-model="isOnNetMode" :label="isOnNetModeLabel" size="xl" />
    <hr />
    <!--<h4 class="pb-2">Roaming</h4>
    <CFormSelect :options="telcoCountries" label="Country" v-model="telcoCountry" />
    <div class="py-1"></div>
    <CFormSelect :options="telcosByCountry" label="Telco" v-model="telco" :disabled="!telcoCountry" />
    <CFormText>Select Country to get the list of telcos</CFormText>-->
  </div>
</template>

<script>
export default {
  computed: {
    mcc: {
      get () {
        return this.$store.state.mcc
      },
      set (value) {
        this.$store.commit('updateMcc', value)
      }
    },
    mnc: {
      get () {
        return this.$store.state.mnc
      },
      set (value) {
        this.$store.commit('updateMnc', value)
      }
    },
    isOnNetMode: {
      get () {
        return this.$store.state.settings.isOnNetMode
      },
      set (newValue) {
        this.$store.dispatch('settings/updateIsOnNetMode', { isOnNetMode: newValue })
      }
    },
    carrierSelectRoutingInformation: {
      get () {
        return this.$store.state.settings.carrierSelectRoutingInformation
      },
      set (newValue) {
        this.$store.commit('settings/setCarrierSelectRoutingInformation', newValue)
      }
    },
    numberPortabilityRoutingInformation: {
      get () {
        return this.$store.state.settings.numberPortabilityRoutingInformation
      },
      set (newValue) {
        this.$store.commit('settings/setNumberPortabilityRoutingInformation', newValue)
      }
    },
    telcoCountries () {
      return this.$store.state.settings.telcoCountries
    },
    telcosByCountry () {
      return (this.$store.state.settings.telcosByCountries[this.telcoCountry].telcosWithCode || [])
    },
    telco: {
      get () {
        return this.$store.state.settings.telco
      },
      set (newValue) {
        this.$store.dispatch('settings/updateTelco', { telco: newValue })
      }
    },
    telcoCountry: {
      get () {
        return this.$store.state.settings.telcoCountry
      },
      set (newValue) {
        this.$store.commit('settings/setTelcoCountry', newValue)
      }
    }
  },
  mounted () {
    this.$store.dispatch('myProviderConfig/getMyProviderConfig')
  },
  methods: {
    defaultOptions (ratingGroup) {
      return [
        this.$store.state.unitTypeMapping[ratingGroup],
        { label: 'time', value: 'time' },
        { label: 'totalVolume', value: 'totalVolume' },
        { label: 'serviceSpecificUnits', value: 'serviceSpecificUnits' }
      ]
    },
    updateMncMccValues () {
      this.$store.commit('updateMcc', this.mcc, { root: true })
      this.$store.commit('updateMnc', this.mnc, { root: true })
    },
    changeUnitType (ratingGroup, newRatingGroupUnitType) {
      const currentState = this.$store.state.unitTypeMapping
      currentState[ratingGroup] = newRatingGroupUnitType
      const newState = currentState
      this.$store.dispatch('unitMapping/update', newState)
    },
    updatecarrierSelectRoutingInformation () {
      this.$store.commit('settings/setCarrierSelectRoutingInformation', this.carrierSelectRoutingInformation)
    },
    updateNumberPortabilityRoutingInformation () {
      this.$store.commit('settings/setNumberPortabilityRoutingInformation', this.numberPortabilityRoutingInformation)
    }
  }
}
</script>
