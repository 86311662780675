<template>
  <CListGroup flush v-if="dataEdrs.length">
    <CListGroupItem v-for="(dataEDR, key) in dataEdrs" :key="key">
      <CCardTitle class="pt-3">{{ dataEDR.name }}</CCardTitle>
      <CTable v-if="dataEDR.isMonetary">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>Time</CTableHeaderCell>
            <CTableHeaderCell class="text-center"></CTableHeaderCell>
            <CTableHeaderCell class="text-center">Volume (MB)</CTableHeaderCell>
            <CTableHeaderCell class="text-center">RG</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Base Amt</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Tax Amt</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Total Amt</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Balance Amt</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="(edr, idx) in dataEDR.edrs" :key="idx">
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1 }">
              {{ edr.time }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
              <font-awesome-icon icon="fa-solid fa-r" v-if="edr.isRoaming" class="text-primary" />
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.rgTotalVolume }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.ratingGroup }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.base }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.tax }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.total }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.newBalanceValue }}
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
      <CTable v-else>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>Time</CTableHeaderCell>
            <CTableHeaderCell class="text-center"></CTableHeaderCell>
            <CTableHeaderCell class="text-center">Volume (MB)</CTableHeaderCell>
            <CTableHeaderCell class="text-center">RG</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Balance (MB)</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="(edr, idx) in dataEDR.edrs" :key="idx">
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1 }">
              {{ edr.time }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
              <font-awesome-icon icon="fa-solid fa-r" v-if="edr.isRoaming" class="text-primary" />
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.rgTotalVolume }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.ratingGroup }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === dataEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.newBalanceValue }}
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
    </CListGroupItem>
  </CListGroup>
  <CCardBody v-else>
    <CCardTitle class="text-center">No data found</CCardTitle>
  </CCardBody>
</template>
<script>
export default {
  props: {
    dataEdrs: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>
