<template>
  <div>
    <MainNav />
    <br />
    <DeviceDetails />
  </div>
</template>

<script>
import MainNav from '@/components/MainNav.vue'
import DeviceDetails from '@/components/DeviceDetails.vue'

export default {
  name: 'HomeView',
  components: {
    MainNav,
    DeviceDetails
  }
}
</script>
