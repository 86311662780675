import { axiosInstance as axios } from '../../utils/axiosInstances'
import * as jose from 'jose'
import router from '@/router'
import getProviderId from '@/utils/providerId'
import { cognitoIdpSignInUrl } from '../common'

export default {
  state: {
    signingIn: false
  },
  getters: {},
  mutations: {
    signInCompleted (state) {
      state.signingIn = false
    },
    startSignIn (state) {
      state.signingIn = true
    }
  },
  namespaced: true,
  actions: {
    async signIn (context, data) {
      try {
        console.log(data)
        context.commit('startSignIn')
        const response = await axios.post(cognitoIdpSignInUrl, data, {
          headers: {
            'Content-Type': 'application/x-amz-json-1.1',
            'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth'
          }
        })
        console.log(response)
        const idToken = response.data.AuthenticationResult.IdToken
        const decodedToken = jose.decodeJwt(idToken)
        const providerId = getProviderId(decodedToken)
        context.commit('setIdToken', idToken, { root: true })
        context.commit('setProviderId', providerId, { root: true })
        router.push('/')
        context.commit('signInCompleted')
        return response
      } catch (error) {
        console.log(error)
        context.commit('signInCompleted')
        router.push('/')
        if (error.response) {
          return error.response
        }
        return error
      }
    }
  }
}
