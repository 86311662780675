
import { axiosInstance as axios, plainAxiosInstance } from '../../utils/axiosInstances'
import { graphQLUrl } from '@/store/common'
import { getCurrentUserQuery } from '@/store/modules/queries'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getCurrentUser (context, testingCredentials) {
      const idToken = context.rootState.idToken
      const axiosToUse = testingCredentials ? plainAxiosInstance : axios
      const response = await axiosToUse.post(graphQLUrl, getCurrentUserQuery(), {
        headers: {
          Authorization: `${idToken}`
        }
      })
      console.log(response)
      const user = response.data.data.getCurrentUser
      context.commit('setCurrentUser', user, { root: true })
    }
  }
}
