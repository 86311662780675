<template>
  <CListGroup flush v-if="textEdrs.length">
    <CListGroupItem v-for="(textEDR, key) in textEdrs" :key="key">
      <CCardTitle class="pt-3">{{ textEDR.name }}</CCardTitle>
      <CTable v-if="textEDR.isMonetary">
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>Time</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Sender</CTableHeaderCell>
            <CTableHeaderCell class="text-center"></CTableHeaderCell>
            <CTableHeaderCell class="text-center">Recipient</CTableHeaderCell>
            <CTableHeaderCell class="text-center">SMS Count</CTableHeaderCell>
            <CTableHeaderCell class="text-center">RG</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Base Amt</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Tax Amt</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Total Amt</CTableHeaderCell>
            <CTableHeaderCell class="text-end">Balance Amt</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="(edr, idx) in textEDR.edrs" :key="idx">
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1 }">
              {{ edr.time }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.fromParty }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-center': true }">
              <font-awesome-icon icon="fa-solid fa-r" v-if="edr.isRoaming" class="text-primary" />
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.toParty }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.rgTextsUsed }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.ratingGroup }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.base }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.tax }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.total }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-end': true }">
              {{ edr.newBalanceValue }}
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
      <CTable v-else>
        <CTableHead>
          <CTableRow>
            <CTableHeaderCell>Time</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Sender</CTableHeaderCell>
            <CTableHeaderCell class="text-center"></CTableHeaderCell>
            <CTableHeaderCell class="text-center">Recipient</CTableHeaderCell>
            <CTableHeaderCell class="text-center">SMS Count</CTableHeaderCell>
            <CTableHeaderCell class="text-center">RG</CTableHeaderCell>
            <CTableHeaderCell class="text-center">Balance</CTableHeaderCell>
          </CTableRow>
        </CTableHead>
        <CTableBody>
          <CTableRow v-for="(edr, idx) in textEDR.edrs" :key="idx">
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1 }">
              {{ edr.time }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.fromParty }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-center': true }">
              <font-awesome-icon icon="fa-solid fa-r" v-if="edr.isRoaming" class="text-primary" />
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.toParty }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.rgTextsUsed }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.ratingGroup }}
            </CTableDataCell>
            <CTableDataCell :class="{ 'balances-table-last-row': idx === textEDR.edrs.length - 1, 'text-center': true }">
              {{ edr.newBalanceValue }}
            </CTableDataCell>
          </CTableRow>
        </CTableBody>
      </CTable>
    </CListGroupItem>
  </CListGroup>
  <CCardBody v-else>
    <CCardTitle class="text-center">No data found</CCardTitle>
  </CCardBody>
</template>
<script>
export default {
  props: {
    textEdrs: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>
